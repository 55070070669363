/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const CoordinatedFiscalVoucher = {
    async list(params) {
        const response = await axios.get('/api/coordinated_fiscal_vouchers', {params})

        return response.data.data
    },
}

export default CoordinatedFiscalVoucher